import React, {useState} from "react"
import { graphql, useStaticQuery } from "gatsby"
import {Container, Row, Col} from "react-bootstrap"
// import Seo from "../../../components/seo"
// import Layout from "../../../components/layout"

import Lightbox from 'fslightbox-react'; 
import Slider from "react-slick";

import "../../../../node_modules/slick-carousel/slick/slick.css"

import NextButton from "../../Elements/Buttons/SliderButtons/NextButton"
import PrevButton from "../../Elements/Buttons/SliderButtons/PrevButton"

// Gallery functions to move slides with Buttons

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button 
        className = {className}
        style={{ ...style, display: "block", background: "transparent", right: '75px'}}
        onClick={onClick}
      >
      <NextButton />
      </button>
  
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button 
        className = {className}
        style={{ ...style, display: "block", background: "transparent", left: '10px', zIndex: 2}}
        onClick={onClick}
      >
      <PrevButton />
      </button>
  
    );
  }

  export default function GalleryBlock() {
        // GALLERY VARS STARTS
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 0
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "slider variable-width",
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
          centerMode: true,
          variableWidth: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // className: "center",
          variableWidth: false,
          centerMode: true,
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // className: "center",
          variableWidth: false,
          // centerMode: true,
        }
      }
    ]
};


const data = useStaticQuery(graphql`
{
  allWpPage(filter: {slug: {eq: "gallery-chans"}}) {
    nodes {
      gallery_chans {
        galleryImages {
          sourceUrl
            localFile {
                id
                childImageSharp {
                  original {
                    src
                  }
                }
            }
        altText
        }
      }
    }
  }
}
`)


const images = data.allWpPage.nodes[0]
// const ImgGallery = images.gallery_chans.galleryImages.map(({localFile, id, altText}, index) => (
//   <button className="button-image" onClick={() => openLightboxOnSlide(index + 1)} >
//     <img src = {localFile.childImageSharp.original.src} className="postgallery-img" key={id} alt={altText} />
//   </button>
// ))

const ImgGallery = images.gallery_chans.galleryImages.map(({localFile, id, altText}, index) => (
  <button className="button-image" onClick={() => openLightboxOnSlide(index + 1)} >
  <img src = {localFile.childImageSharp.original.src} key={id} alt={altText} style={{width: '450px'}} />
  </button>
))

const Sources = images.gallery_chans.galleryImages.map(({localFile, id, altText}) => (
  <img src = {localFile.childImageSharp.original.src} key={id} alt={altText} />
))



function openLightboxOnSlide(number) {
    setLightboxController({
    toggler: !lightboxController.toggler,
    slide: number
  });
}
  // END GALLERY VARS 


  return (
    <>
    {/* <Seo 
      title = {SeoTitle}
      description = {SeoDescription}
      keywords = {SeoKeywords.toString()}
    /> */}

        {/* GALLERY ROW  STARTS */}
        <Container>
            <Row>
                <Col>
                    <Lightbox
                        toggler={lightboxController.toggler}
                        slide={lightboxController.slide}
                        sources={Sources}
                    />
                    <Slider {...settings} className="gallery-carousel">
                                              {ImgGallery}
                    </Slider>
                </Col>
            </Row>
        </Container>
        {/* GALLERY ROW ENDS  */}

    </>
  )

  }

