import React from 'react';
import GalleryChan from './gallery';




const Gallery = () => {
    return(
        <>
        <GalleryChan />
        </>        
    )
}

export default Gallery