import React from 'react';
import Seo from "../components/seo"
import { Container, Row, Col } from 'react-bootstrap';
import Layout from "../components/layout"
import GalleryChans from '../components/Modules/Gallery'


const Gallery = () => {
    return(
        <>
            <Seo 
                title="Фотогалерея банных чанов в Екатеринбурге"
                description="Фотографии банных чанов. Галерея того, что мы производим."
            />
        <Layout>
            <Container>
                <Row>
                    <Col>
                        <p className="section-title section-title--green">ФОТОГАЛЕРЕЯ</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <GalleryChans />
                        {/* Галерея скоро будет представлена */}
                    </Col>
                </Row>
            </Container>
        </Layout>
            
        </>        
    )
}

export default Gallery